<template>
  <b-container fluid>
       <b-row>
         <b-overlay :show="loading">
            <template>
                <b-row>
                    <b-col md="12">
                          <table class="table table-sm table-borderless">
                            <tr>
                                <th style="width: 20%">{{ $t('associationPnl.proposal_no')}}</th>
                                <td style="width: 30%">: {{ items.proposal_id }}</td>
                                <th style="width: 20%">{{ $t('eBizProposal.council_name')}}</th>
                                <td style="width: 30%">: {{ getCouncilName(items.council_info_id) }}</td>
                            </tr>
                            <tr>
                                <th style="width: 20%">{{ $t('eBizProposal.association_name')}}</th>
                                <td style="width: 30%">: {{ getAssociationName(items.association_info_id) }}</td>
                            </tr>
                            <!-- <tr>
                                <th style="width: 20%">{{$t('associationPnl.proposal_type')}}</th>
                                <td style="width: 30%">
                                 <b-form-checkbox-group
                                  v-model="items.proposal_type_ids"
                                  :options="proposalTypeList"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  </b-form-checkbox-group>
                                </td>
                            </tr> -->
                        </table>
                    </b-col>
                </b-row>
                 <b-row>
                  <b-col md="12">
                    <ValidationObserver ref="mainForm" v-slot="{ handleSubmit, reset }">
                        <!-- <b-overlay :show="loading"> -->
                            <b-form @submit.prevent="handleSubmit(updateData)" @reset.prevent="reset" autocomplete="off" enctype="multipart/form-data">
                                <!-- &&&&&&&&&&&&&& Program Details Start&&&&&&&&&&&&& -->
                                    <div class="table-wrapper table-responsive">
                                    <table class="table table-striped table-hover table-bordered">
                                        <thead>
                                            <tr class="bg-primary">
                                                <th scope="col" style="width: 10%;"><div>{{ $t('globalTrans.sl_no') }}</div></th>
                                                <th scope="col" class="text-center">
                                                    <div>{{$t('associationPanel.program_name')}} {{$t('globalTrans.en')}}</div>
                                                </th>
                                                <th scope="col" class="text-center">
                                                    <div>{{$t('associationPanel.program_name')}} {{$t('globalTrans.bn')}}</div>
                                                </th>
                                                <th scope="col" class="text-center">
                                                    <div> {{ $t('associationPanel.duration') }}</div>
                                                </th>
                                                <th scope="col" class="text-center">
                                                    <div> {{ $t('associationPanel.amount') }}</div>
                                                </th>
                                                <th scope="col" class="text-center">
                                                    <div> {{ $t('globalTrans.attachment') }}</div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template v-if="proposalDetails && proposalDetails.length > 0">
                                                <tr v-for="(item, index) in proposalDetails" :key="index">
                                                    <td  class="text-center">{{ $n(index+1) }}</td>
                                                    <!-- <td class="text-center">{{ currentLocale == 'bn' ? item.programe_name_bn : item.programe_name_en }}</td> -->
                                                    <td style="width: 40%" class="text-center" >
                                                        <ValidationProvider name="Program Name (En)" :vid="`program_name_en${index}`" rules="required" v-slot="{ errors }">
                                                            <b-form-group
                                                            :label-for="`program_name_en${index}`">
                                                            <b-form-input
                                                                id="program_name_en"
                                                                v-model="item.programe_name_en"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                            ></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </td>
                                                    <td style="width: 40%" class="text-center" >
                                                        <ValidationProvider name="Program Name (Bn)" :vid="`program_name_bn${index}`" rules="required" v-slot="{ errors }">
                                                            <b-form-group
                                                            :label-for="`program_name_bn${index}`">
                                                            <b-form-input
                                                                id="program_name_bn"
                                                                v-model="item.programe_name_bn"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                            ></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </td>
                                                    <td class="text-center">{{ $n(item.duration) }}</td>
                                                    <td class="text-right">{{ $n(item.amount) }}</td>
                                                    <td class="text-center">
                                                        <span><a target="_blank" class="btn btn-primary btn-sm btn-outline mr-2" :href="eBizServiceBaseUrl+'download-attachment?file='+item.attachment" :title="$t('globalTrans.download')"><i class="ri-file-download-line"></i>&nbsp;</a></span>
                                                    </td>
                                                </tr>
                                            </template>
                                            <template v-else>
                                                <tr>
                                                    <td colspan="13" align="center">{{ $t('globalTrans.noDataFound') }}</td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                    </div>
                                    <!-- &&&&&&&&&&&&&& Program Details End&&&&&&&&&&&&&&& -->
                                     <b-row class="text-right mb-3">
                                            <b-col>
                                                <b-button type="submit" variant="warning" class="mr-2 btn-sm">{{ $t('globalTrans.update') }}</b-button>
                                                <b-button variant="danger" class="btn-sm"  @click="$bvModal.hide('details')">{{ $t('globalTrans.cancel') }}</b-button>
                                            </b-col>
                                        </b-row>
                             </b-form>
                      <!-- </b-overlay> -->
                    </ValidationObserver>
                  </b-col>
                </b-row>
            </template>
          </b-overlay>
        </b-row>
  </b-container>
</template>
<script>
import RestApi, { eBizServiceBaseUrl } from '@/config/api_config'
import { proposalUpdate } from '../../api/routes'
export default {
    props: ['items'],
    components: {
    },
    created () {
      this.proposalDetails = this.items.details
    },
    mounted () {
        // core.index()
    },
    data () {
        return {
            valid: null,
            proposalDetails: [],
            eBizServiceBaseUrl: eBizServiceBaseUrl
        }
    },
    computed: {
         loading: function () {
            return this.$store.state.commonObj.loading
        },
        currentLocale () {
            return this.$i18n.locale
        },
        sourceOfFundList: function () {
            const list = [
                { value: 1, text: this.$i18n.locale === 'en' ? 'Gov' : 'সরকারি' },
                { value: 2, text: this.$i18n.locale === 'en' ? 'Project' : 'প্রজেক্ট' },
                { value: 3, text: this.$i18n.locale === 'en' ? 'Other' : 'অন্যান্য' }
            ]
           return list
        }
    },
    methods: {
        getCouncilName (councilNameId) {
            const cateObj = this.$store.state.EBizManagementSystemService.commonObj.councilInfoList.filter(item => item.status === 1).find(item => item.value === councilNameId)
            if (cateObj !== undefined) {
                if (this.$i18n.locale === 'bn') {
                    return cateObj.text_bn
                } else {
                    return cateObj.text_en
                }
            }
        },
        proposalTypeList () {
            return this.$store.state.EBizManagementSystemService.commonObj.proposalTypeList.filter(item => item.status === 1)
        },
        getAssociationName (associationId) {
            const cateObj = this.$store.state.EBizManagementSystemService.commonObj.associationInfoList.filter(item => item.status === 1).find(item => item.value === associationId)
            if (cateObj !== undefined) {
                if (this.$i18n.locale === 'bn') {
                    return cateObj.text_bn
                } else {
                    return cateObj.text_en
                }
            }
        },
        getFiscalYear (yearId) {
            const cateObj = this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1).find(item => item.value === yearId)
            if (cateObj !== undefined) {
                if (this.$i18n.locale === 'bn') {
                    return cateObj.text_bn
                } else {
                    return cateObj.text_en
                }
            }
        },
        getProjectName (projectId) {
            const cateObj = this.$store.state.EBizManagementSystemService.commonObj.projectNameList.find(item => item.value === projectId)
            if (cateObj !== undefined) {
                if (this.$i18n.locale === 'bn') {
                    return cateObj.text_bn
                } else {
                    return cateObj.text_en
                }
            }
        },
        soFundName (fundId) {
            const cateObj = this.sourceOfFundList.find(item => item.value === fundId)
            if (cateObj !== undefined) {
                return cateObj.text
            }
        },
        async updateData () {
            const isValid = await this.$refs.mainForm.validate()
            if (isValid && this.proposalDetails.length > 0) {
                this.$store.dispatch('mutateCommonProperties', { loading: true })
                let result = null
                const loadingState = { loading: false, listReload: false }
                if (this.items.id) {
                    result = await RestApi.putData(eBizServiceBaseUrl, `${proposalUpdate}/${this.items?.id}`, this.proposalDetails)
                }
                loadingState.listReload = true
                this.$store.dispatch('mutateCommonProperties', loadingState)

                if (result.success) {
                    this.$store.dispatch('EBizManagementSystemService/mutateCommonObj', { hasDropdownLoaded: false })
                    this.$toast.success({
                        title: 'Success',
                        message: this.$t('globalTrans.update_msg'),
                        color: '#D6E09B'
                    })
                    this.$bvModal.hide('details')
                } else {
                    this.$refs.mainForm.setErrors(result.errors)
                }
            }
        }
    }
}

</script>
